import React from 'react';
import whatsappIcon from '../assets/whatsapp-icon-concept.png'; // Adjust the path as needed

const WhatsAppButton = () => {
  const phoneNumber = '918921749886';
  const whatsappUrl = `https://wa.me/${phoneNumber}`;

  return (
    <a
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 bg-green-500 hover:bg-green-600 text-white p-2 rounded-full shadow-lg transition-all duration-300 z-50 flex items-center justify-center"
      style={{
        width: '60px',
        height: '60px',
      }}
      aria-label="Chat on WhatsApp"
    >
      <img 
        src={whatsappIcon} 
        alt="WhatsApp" 
     
      />
    </a>
  );
};

export default WhatsAppButton;