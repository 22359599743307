import React, { useState, useEffect } from 'react';
import { FaLightbulb, FaBullseye } from 'react-icons/fa';
import about1 from '../assets/sep6.webp';
import about2 from '../assets/sep2.jpg';
import about3 from '../assets/sep5.webp';
import about4 from '../assets/energy-supply-system (2).jpg';
import about from '../assets/under.jpg';
import about5 from '../assets/biotank.jpg';
import about6 from '../assets/sep9.webp';
import persn1 from '../assets/person1.jpg';
import persn2 from '../assets/person2.jpg';
import persn3 from '../assets/person3.jpg';
import persn4 from '../assets/cheerful-clerk-worker-wearing-suit-tie-looking-camera-office (1).jpg';
import Footer from './Footer';
import { useTransition, animated } from 'react-spring';

const testimonials = [
  {
    text: "അന്ന ഡ്രെയിനേജ് സിസ്റ്റം സൊല്യൂഷൻസ് ഞങ്ങളുടെ വസ്തുവിന് അസാധാരണമായ പരിവർത്തനം നൽകി. അവരുടെ സെപ്റ്റിക് ടാങ്ക് ഇൻസ്റ്റലേഷനും എസ്ടിപി സിസ്റ്റങ്ങളും ഞങ്ങളുടെ വീടിന്റെ കാര്യക്ഷമതയും സുസ്ഥിരതയും വളരെയധികം മെച്ചപ്പെടുത്തി. അവരുടെ വൈദഗ്ധ്യം അതുല്യമാണ്.",
    name: "ജെയ്ൻ ആൻഡേഴ്സൺ",
    image: persn2,
  },
  {
    text: "അന്ന ഡ്രെയിനേജ് സിസ്റ്റം സൊല്യൂഷൻസ് അവരുടെ വികസിത മഴവെള്ള സംഭരണ പരിഹാരങ്ങളിലൂടെ ഞങ്ങളുടെ വസ്തുവിനെ പരിവർത്തനം ചെയ്തു. അവരുടെ ജോലി പ്രൊഫഷണലും അത്യന്തം ഫലപ്രദവുമാണ്, ഞങ്ങളുടെ ജല പരിപാലനത്തിൽ ഗണ്യമായ സ്വാധീനം ചെലുത്തുന്നു.",
    name: "ജോൺ ഡോ",
    image: persn4,
  },
  {
    text: "അന്ന ഡ്രെയിനേജ് സിസ്റ്റം സൊല്യൂഷൻസിലെ ടീം ഞങ്ങളുടെ വസ്തുവിന് ഒരു മികച്ച എസ്ടിപി സിസ്റ്റം നൽകി. സംയോജനം സുഗമമായിരുന്നു, സിസ്റ്റം തകരാറില്ലാതെ പ്രവർത്തിക്കുന്നു. ഇത്തരം വിശ്വസനീയമായ പരിഹാരങ്ങൾ ഉള്ളത് ആശ്വാസകരമാണ്.",
    name: "മൈക്കൽ.ഒ.പി",
    image: persn1,
  },
  {
    text: "അന്ന ഡ്രെയിനേജ് സിസ്റ്റം സൊല്യൂഷൻസ് നൽകിയ സെപ്റ്റിക് ടാങ്ക് പരിഹാരങ്ങളിൽ നിന്ന് ഞങ്ങളുടെ വസ്തു വളരെയധികം പ്രയോജനം നേടിയിട്ടുണ്ട്. സങ്കീർണ്ണമായ ഇൻസ്റ്റലേഷനുകളും പരിപാലനവും കൈകാര്യം ചെയ്യുന്നതിലുള്ള അവരുടെ വൈദഗ്ധ്യം വ്യക്തമാണ്, ഫലങ്ങളിൽ ഞങ്ങൾ അത്യന്തം സംതൃപ്തരാണ്.",
    name: "സി.എച്ച് ജോൺ",
    image: persn3,
  },
];

const TestimonialCarousel = ({ startIndex = 0 }) => {
  const [index, setIndex] = useState(startIndex);

  const transitions = useTransition(index, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((state) => (state + 1) % testimonials.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-full">
      {transitions((style, i) => (
        <animated.div
          key={i}
          style={style}
          className="absolute inset-0 bg-white shadow-lg rounded-lg overflow-hidden flex flex-col justify-between p-4 sm:p-6"
        >
                     <p class="font-poppins text-container text-gray-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 leading-relaxed text-sm sm:text-base">
            {testimonials[i].text}
          </p>
          <div className="flex items-center">
            <img src={testimonials[i].image} alt={testimonials[i].name} className="w-12 h-12 rounded-full mr-4" />
            <span className="font-bold text-sm sm:text-base">{testimonials[i].name}</span>
          </div>
        </animated.div>
      ))}
    </div>
  );
};

const About = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative h-[500px] bg-gray-800 mb-12">
        <img src={about} alt="Hero" className="w-full h-full object-cover opacity-70" />
      </div>
      
      {/* Main Content */}
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Image Grid */}
          <div className="md:w-1/2 grid grid-cols-3 grid-rows-3 gap-2 h-[400px]">
            <img src={about1} alt="സെപ്റ്റിക് ടാങ്ക് ഇൻസ്റ്റലേഷൻ" className="col-span-2 row-span-2 w-full h-full object-cover rounded-lg" />
            <img src={about2} alt="എസ്ടിപി സിസ്റ്റം" className="w-full h-full object-cover rounded-lg" />
            <img src={about4} alt="മഴവെള്ള സംഭരണം" className="w-full h-full object-cover rounded-lg" />
            <img src={about3} alt="വികസിത ഡ്രെയിനേജ് പരിഹാരങ്ങൾ" className="col-span-2 w-full h-full object-cover rounded-lg" />
          </div>
          
          {/* Text Content */}
          <div className="md:w-1/2 flex flex-col justify-center">
            <h3 className="text-teal-500 text-lg mb-2 px-4 sm:px-6 md:px-8 lg:px-10">ഞങ്ങളെക്കുറിച്ച്</h3>
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 px-4 sm:px-6 md:px-8 lg:px-10">വികസിത ഡ്രെയിനേജ് പരിഹാരങ്ങളിലൂടെ കാര്യക്ഷമതയും സുഖസൗകര്യവും നേടുക</h2>
            <div className="right-aligned-container">
            <p class="font-poppins text-container text-gray-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 leading-relaxed text-sm sm:text-base">
                അന്ന ഡ്രെയിനേജ് സിസ്റ്റം സൊല്യൂഷൻസിന്റെ മികവും നവീനതയും അനുഭവിക്കുക. ഞങ്ങളുടെ വികസിത സെപ്റ്റിക് ടാങ്ക് സിസ്റ്റങ്ങൾ, എസ്ടിപി പരിഹാരങ്ങൾ, മഴവെള്ള സംഭരണ സാങ്കേതികവിദ്യകൾ എന്നിവ നിങ്ങളുടെ വസ്തുവിന്റെ കാര്യക്ഷമത, സുസ്ഥിരത, വിശ്വാസ്യത എന്നിവ വർദ്ധിപ്പിക്കുന്നതിനായി രൂപകൽപ്പന ചെയ്തിരിക്കുന്നു.
              </p>
              <p class="font-poppins text-container text-gray-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 leading-relaxed text-sm sm:text-base">
                ഞങ്ങളുടെ സമഗ്രമായ പരിഹാരങ്ങളോടെ, മെച്ചപ്പെട്ട ജല പരിപാലനം, ഊർജ്ജ സംരക്ഷണം, സുസ്ഥിര പ്രവർത്തനങ്ങൾ എന്നിവ ആസ്വദിക്കുക. അന്ന ഡ്രെയിനേജ് സിസ്റ്റം സൊല്യൂഷൻസിന് നിങ്ങളുടെ വസ്തുവിനെ എങ്ങനെ പരിവർത്തനം ചെയ്യാൻ കഴിയുമെന്ന് കണ്ടെത്തുക.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Vision and Mission */}
      <div className="bg-gray-100 py-12 mt-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row gap-8">
            <div className="md:w-1/3 bg-white p-6 rounded-lg shadow">
              <FaLightbulb className="text-4xl text-blue-500 mb-4" />
              <h3 className="text-xl font-bold mb-2 px-2 sm:px-3 md:px-4 lg:px-5">ഞങ്ങളുടെ വീക്ഷണം</h3>
              <p class="font-poppins text-container text-gray-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 leading-relaxed text-sm sm:text-base">
                  അന്ന ഡ്രെയിനേജ് സിസ്റ്റം സൊല്യൂഷൻസ് വികസിത സാങ്കേതികവിദ്യ ഉപയോഗിച്ച് ഫലപ്രദമായ ഡ്രെയിനേജ് സിസ്റ്റങ്ങളിലൂടെ ദൈനംദിന ജീവിതം മെച്ചപ്പെടുത്താൻ ലക്ഷ്യമിടുന്നു. കാര്യക്ഷമവും സുസ്ഥിരവുമായ പരിസ്ഥിതികൾ സൃഷ്ടിക്കാനും കണക്റ്റഡും പരിസ്ഥിതി സൗഹൃദവുമായ ഒരു ലോകം പ്രോത്സാഹിപ്പിക്കാനും ഞങ്ങൾ ശ്രമിക്കുന്നു.
                </p>
            </div>
            <div className="md:w-1/3 bg-white p-6 rounded-lg shadow">
              <FaBullseye className="text-4xl text-blue-500 mb-4" />
              <h3 className="text-xl font-bold mb-2 px-2 sm:px-3 md:px-4 lg:px-5">ഞങ്ങളുടെ ദൗത്യം</h3>
              <p class="font-poppins text-container text-gray-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 leading-relaxed text-sm sm:text-base">
                ഞങ്ങളുടെ സമഗ്ര സേവനങ്ങളിൽ സെപ്റ്റിക് ടാങ്ക് ഇൻസ്റ്റലേഷനുകൾ, എസ്ടിപി സിസ്റ്റങ്ങൾ, മഴവെള്ള സംഭരണ പരിഹാരങ്ങൾ എന്നിവ ഉൾപ്പെടുന്നു. നിങ്ങളുടെ വസ്തുവിന്റെ സുഖസൗകര്യം, സുസ്ഥിരത, കാര്യക്ഷമത എന്നിവ വർദ്ധിപ്പിക്കുന്നതിനായി ഞങ്ങൾ പ്രതിജ്ഞാബദ്ധരാണ്.
              </p>
            </div>
            <div className="md:w-1/3 bg-white p-6 rounded-lg shadow">
              <h3 className="text-xl font-bold mb-2">ISO റേറ്റിംഗ് സെപ്റ്റിക് ടാങ്കുകൾ</h3>
              <img src={about5} alt="പരിവർത്തനം" className="w-full h-40 object-cover rounded" />
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="py-12 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-8">സാക്ഷ്യപത്രങ്ങൾ</h2>
          <h3 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8">സ്മാർട്ട് ഡ്രെയിനേജ് സിസ്റ്റങ്ങൾ ഉപയോഗിച്ച് പ്രവർത്തനക്ഷമതയും വിശ്വാസ്യതയും നേടുക</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="h-64 md:h-auto">
              <img src={about6} alt="വികസിത ഡ്രെയിനേജ് പരിഹാരങ്ങൾ" className="w-full h-full object-cover rounded-lg" />
            </div>
            <div className="h-64 md:h-auto bg-white shadow-lg rounded-lg overflow-hidden">
              <TestimonialCarousel startIndex={0} />
            </div>
            <div className="h-64 md:h-auto bg-white shadow-lg rounded-lg overflow-hidden">
              <TestimonialCarousel startIndex={1} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;

