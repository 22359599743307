import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import Logo from '../assets/Logo Updt.png';


const Navbar = () => {
  
  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const logoAnimation = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 300, friction: 20 },
  });

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 50);
      setIsScrolled(currentScrollPos > 50);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const navLinks = [
    { name: 'പൊതുവായി', path: '/' },
    { name: 'ഞങ്ങളേക്കുറിച്ച്', path: '/about' },
    { name: 'ബന്ധപ്പെടുക', path: '/contact' },
  ];

  const NavLink = ({ name, path }) => (
    <Link
      to={path}
      className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
        location.pathname === path
          ? 'text-blue-600'
          : 'text-gray-700 hover:text-blue-600'
      }`}
    >
      {name}
    </Link>
  );


 
  const CallIcon = () => (
    <a
      href="tel:+918921749886"
      className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200"
      aria-label="Call Us"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
        />
      </svg>
    </a>
  );

  return (
    <>
      <nav
        className={`fixed top-0 left-0 w-full z-50 transition-transform duration-300 ${
          visible ? 'translate-y-0' : '-translate-y-full'
        } ${isScrolled ? 'bg-white shadow-md' : 'bg-transparent'}`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-20">
            <div className="flex items-center">
              <Link to="/" className="flex items-center">
                <animated.img
                  style={logoAnimation}
                  src={Logo}
                  alt="Company Logo"
                  className="h-16 w-auto sm:h-20 md:h-24"
                />
              </Link>
            </div>
            <div className="hidden md:flex space-x-6">
              {navLinks.map((link) => (
                <NavLink key={link.name} {...link} />
              ))}
            </div>
            <div className="hidden md:flex items-center space-x-4">

              <CallIcon />
            </div>
            <div className="md:hidden flex items-center space-x-4">
              
              <CallIcon />
              <button
                onClick={toggleNavbar}
                className="text-gray-700 hover:text-blue-600 focus:outline-none"
                aria-label="Toggle Menu"
              >
                {isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden bg-white shadow-md">
            <div className="px-4 pt-4 pb-6 space-y-4">
              {navLinks.map((link) => (
                <Link
                  key={link.name}
                  to={link.path}
                  onClick={toggleNavbar}
                  className={`block text-lg font-medium ${
                    location.pathname === link.path
                      ? 'text-blue-600'
                      : 'text-gray-700 hover:text-blue-600'
                  }`}
                >
                  {link.name}
                </Link>
              ))}
              <div className="flex space-x-4">
                
                
              </div>
            </div>
          </div>
        )}
      </nav>
      {/* Spacer */}
      <div className="h-20"></div>
    </>
  );
};

export default Navbar;