import React, { useState, useEffect } from "react";
import { MapPin, Mail, Phone, ArrowUp } from 'lucide-react';
import Bx from '../assets/Log.png'

const Footer = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className="relative">
            <footer className="bg-gray-800 text-white py-8 md:py-12">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                        {/* Logo and Social Icons */}
                        <div className="flex flex-col items-center sm:items-start">
                            <img src={Bx} alt="anna Logo" className="h-20 md:h-16 mb-4" />
                            <div className="flex justify-center w-full sm:justify-start">
                                <div className="grid grid-cols-3 gap-4">
                                    <a href="https://www.instagram.com/" className="text-white hover:text-gray-300">
                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                                        </svg>
                                    </a>
                                    <a href="https://www.facebook.com/" className="text-white hover:text-gray-300">
                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                                        </svg>
                                    </a>
                                    <a href="https://www.youtube.com/" className="text-white hover:text-gray-300">
                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Links */}
                        <div className="text-center sm:text-left">
                            <h3 className="text-lg font-semibold mb-4 text-[#009DB8]">Links</h3>
                            <ul className="space-y-2">
                                <li><a href="/about" className="hover:text-gray-300">About</a></li>
                                <li><a href="/services" className="hover:text-gray-300">Services</a></li>
                                <li><a href="/contact" className="hover:text-gray-300">Contact</a></li>
                            </ul>
                        </div>

                        {/* Site Policy */}
                        <div className="text-center sm:text-left">
                            <h3 className="text-lg font-semibold mb-4 text-[#009DB8]">Site Policy</h3>
                            <ul className="space-y-2">
                                <li><a href="/terms" className="hover:text-gray-300">Terms&Conditions</a></li>
                                <li><a href="/privacy" className="hover:text-gray-300">Privacy Policy</a></li>
                                <li><a href="/help" className="hover:text-gray-300">Help Center</a></li>
                            </ul>
                        </div>

                        {/* Address */}
                        <div className="text-center sm:text-left">
                            <h3 className="text-lg font-semibold mb-4 text-[#009DB8]">Address</h3>
                            <div className="space-y-4">
                                <div className="flex items-start justify-center sm:justify-start">
                                    <MapPin className="w-5 h-5 text-white mr-2 mt-2 flex-shrink-0" />
                                    <p className="mb-2">
                                        Anna Drainage System Solutions
                                        <br />
                                        Mannuthy
                                        <br />
                                        Thrissur,Kerala,680656
                                    </p>
                                </div>
                                <div className="flex items-center justify-center sm:justify-start">
                                    <Mail className="w-5 h-5 text-white mr-2 flex-shrink-0" />
                                    <p className="mb-2">Annasolutions@gmail.com</p>
                                </div>
                                <div className="flex items-center justify-center sm:justify-start">
                                    <Phone className="w-5 h-5 text-white mr-2 flex-shrink-0" />
                                    <p>+918921749886</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {showButton && (
                <div className="fixed bottom-4 right-4 z-50">
                   
                </div>
            )}
        </div>
    )
}

export default Footer;