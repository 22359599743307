import homeAutomationImage from "../assets/pikaso_reimagine_Several-black-rain-barrels-in-a-garden-setting-sur.jpeg";
import homeTheatreImage from "../assets/septic.jpeg";
import gateAutomationImage from "../assets/stp1.jpeg";
import React, { useState, useEffect, useRef } from "react";
import BgTex from "../assets/kerala2.jpeg";
import BgTexture from "../assets/pik.jpeg";
import BbImg from "../assets/drainage solutions.jpeg";
import Btv from "../assets/reconstruction.jpg";
import Bph from "../assets/old man'.jpg";
import Bwt from "../assets/irrigation-rice-fields-with-pump-wells-canal-system (1).jpg";
import Bsol from "../assets/biotank.jpg";
import Lock from "../assets/smart-lock_10995164.png";
import Monitor from "../assets/sleeping_7059894.png";
import Wireless from "../assets/cast_6038131.png";
import Icon1 from "../assets/Image2.png"; 
import Icon2 from "../assets/Image3.png";
import Icon3 from "../assets/Image4.png";
import Icon4 from "../assets/Image5.png";
import Footer from "./Footer";
const AnimatedNumberOnScroll = ({ target }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    const currentRef = ref.current; // Store ref.current in a variable

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const increment = target / 100;
    const duration = 2000;
    const intervalTime = duration / (target / increment);

    const interval = setInterval(() => {
      setCount((prevCount) => {
        const nextCount = prevCount + increment;
        if (nextCount >= target) {
          clearInterval(interval);
          return target;
        }
        return nextCount;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [target, isVisible]);

  return <span ref={ref}>{Math.floor(count)} +</span>;
};
const Home = () => {
  return (
    <div className="overflow-x-hidden">
      <div
        className="min-h-screen bg-cover bg-center bg-no-repeat relative"
        style={{ backgroundImage: `url(${BgTexture})` }}
      >
  <div>
  <div className="relative min-h-screen p-4 sm:p-8 md:p-16 lg:p-20 pt-[100px] sm:pt-[120px] md:pt-[140px]">
  <div className="mb-8 md:mb-0">
    <div className="max-w-xl text-white">
      <div className="w-full">
        <div className="text-[#FFF250] text-xs sm:text-sm font-bold py-1 px-3 inline-block mb-2 bg-opacity-20 bg-[#FFF250]">
          എപ്പോഴും നിയന്ത്രണത്തിലാണ്
        </div>
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-3 leading-tight">
          സ്മാർട്ട് ഡ്രെയിനേജ് സൊല്യൂഷനുകൾ
          <span className="text-[#009DB8]"> ഉപയോഗിച്ച് </span>
          നിങ്ങളുടെ വീടിൻ്റെ കാര്യക്ഷമത വർദ്ധിപ്പിക്കുക.
        </h1>
        <p className="text-xs sm:text-sm md:text-base mb-4">
        വീട് പണിയുമ്പോൾ ഏറ്റവും കൂടുതൽ ശ്രദ്ധിക്കേണ്ട ഒരു ഏരിയയാണ് ഡ്രൈനേജ് സിസ്റ്റം എത്ര നല്ല വീടായാലും ഡ്രൈനേജ് സിസ്റ്റം പ്രോപ്പറായി സിസ്റ്റമാറ്റിക്കായി നിർമ്മിച്ചില്ലെങ്കിൽ ഇടയ്ക്കിടെ മെയിൻറനൻസ് ചെയ്യേണ്ടതായും മനസ്സമാധാനം നഷ്ടപ്പെടുകയും പണം നഷ്ടപ്പെടുകയും ചെയ്യുന്നത് ഇപ്പോൾ സാധാരണയാണ്.
        എന്നാൽ ഇതാ ഒരു പരിഹാരം  നിങ്ങളുടെ വീടിൻറെ മുഴുവൻ ഡ്രൈനേജ് സിസ്റ്റവും വളരെ ശാസ്ത്രീയമായി ഗ്യാരണ്ടിയോടുകൂടെ നിർമ്മിച്ചു നൽകുന്നു. Anna Drainage System Solutions മണ്ണുത്തി തൃശ്ശൂർ.
        </p>
        <button className="bg-[#009DB8] text-white font-bold py-2 px-4 sm:px-6 rounded-full hover:bg-[#007A8C] transition-colors duration-300 text-sm sm:text-base">
          കൂടുതൽ കണ്ടെത്തുക
        </button>
      </div>
    </div>
  </div>
</div>

          <div className="bg-[ #cceeff] bg-opacity-80 text-white p-4 md:p-8 rounded-[25px] mt-8 md:mt-0 md:absolute md:bottom-0 md:left-20 md:right-20 overflow-y-auto max-h-[80vh]">
            <div className="container mx-auto flex flex-col space-y-6">
              <div className="flex flex-col md:flex-row md:space-x-4 space-y-6 md:space-y-0">
                <div className="flex-1 flex items-start space-x-4">
                  <div className="bg-[#FFF250] rounded-full p-3 flex-shrink-0">
                    <img src={Wireless} alt="" className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="font-bold text-lg">സ്മാർട്ട് ഡ്രെയിനേജ് സൊല്യൂഷൻസ്</h3>
                    <p className="font-poppins text-container text-white-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 text-left leading-relaxed text-sm sm:text-base">
                    തത്സമയം ഡ്രെയിനേജ് നിരീക്ഷിക്കുകയും നിയന്ത്രിക്കുകയും ചെയ്യുന്ന സ്മാർട്ട് ഡ്രെയിനേജ് സൊല്യൂഷനുകൾ, ലളിതമായ ഇൻസ്റ്റാളേഷൻ, കുറഞ്ഞ അറ്റകുറ്റപ്പണികൾ, വിപുലമായ കേബിളിംഗ് ഇല്ലാതെ ഒപ്റ്റിമൈസ് ചെയ്ത പ്രകടനം എന്നിവ വാഗ്ദാനം ചെയ്യുന്നു.
                    </p>
                  </div>
                </div>
                <div className="flex-1 flex items-start space-x-4">
                  <div className="bg-[#FFF250] rounded-full p-3 flex-shrink-0">
                    <img src={Monitor} alt="" className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="font-bold text-lg">24/7 നിരീക്ഷണം</h3>
                    <p className="font-poppins text-container text-white-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 text-left leading-relaxed text-sm sm:text-base">
                    എസ്ടിപി, മഴവെള്ള സംഭരണം, സെപ്റ്റിക് ടാങ്കുകൾ എന്നിവയുടെ തുടർച്ചയായ വയർലെസ് നിരീക്ഷണം, കാര്യക്ഷമമായ ജല മാനേജ്മെൻ്റും അറ്റകുറ്റപ്പണി ആവശ്യങ്ങൾക്കായി തത്സമയ അലേർട്ടുകളും ഉറപ്പാക്കുന്നു.
                    </p>
                  </div>
                </div>
                <div className="flex-1 flex items-start space-x-4">
                  <div className="bg-[#FFF250] rounded-full p-3 flex-shrink-0">
                    <img src={Lock} alt="" className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="font-bold text-lg">സ്മാർട്ട് ഡ്രെയിൻ ലോക്ക്</h3>
                    <p className="font-poppins text-container text-white-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 text-left leading-relaxed text-sm sm:text-base">
                    കാര്യക്ഷമമായ ജല പരിപാലനത്തിനായി ബന്ധിപ്പിച്ച എസ്ടിപി, മഴവെള്ള സംഭരണം, സെപ്റ്റിക് ടാങ്ക് സംവിധാനങ്ങൾ എന്നിവയുടെ വിദഗ്ദ രൂപകൽപ്പന, നടപ്പാക്കൽ, പരിപാലനം.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="min-h-screen bg-white flex items-center py-16">
        <div className="container mx-auto px-4 md:px-10">
          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="w-full md:w-1/2 lg:w-2/5 mb-8 md:mb-0 md:pr-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="aspect-square  rounded-lg overflow-hidden">
                  <img
                    src={Bph}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="aspect-square  rounded-lg overflow-hidden">
                  <img
                    src={Bwt}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="aspect-square  rounded-[25px] overflow-hidden">
                  <img
                    src={Bsol}
                    alt=""
                    className="w-full h-auto max-w-[256px] max-h-[256px] object-cover rounded-[25px]"
                  />
                </div>
                <div className="aspect-square  rounded-lg overflow-hidden">
                  <img
                    src={Btv}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>

            <div className="w-full px-4 py-8 md:w-1/2 lg:w-3/5 md:pl-4 md:pt-16">
  <div className="text-[#009DB8] text-sm font-bold mb-3 md:mb-4">
    ഞങ്ങളേക്കുറിച്ച്
  </div>
  <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 md:mb-6 text-black leading-tight">
  <span className="block mb-2 sm:mb-0">
    സ്മാർട്ട് ഡ്രെയിനേജ് സംവിധാനങ്ങൾ
  </span>
  <span className="block mb-2 sm:mb-0">
    ഉപയോഗിച്ച് കാര്യക്ഷമതയും
  </span>
  <span className="block">
    വിശ്വാസ്യതയും കൈവരിക്കുക
  </span>
</h2>
<p class="font-poppins text-container text-gray-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 leading-relaxed text-sm sm:text-base">
    അന്ന ഡ്രെയിനേജ് സിസ്റ്റം സൊല്യൂഷനുകൾ ഉപയോഗിച്ച് മനസ്സമാധാനവും വിപുലമായ സംരക്ഷണവും അനുഭവിക്കുക. ഞങ്ങളുടെ നൂതന സംവിധാനങ്ങൾ കാര്യക്ഷമത, സുസ്ഥിരത, നിയന്ത്രണം എന്നിവ തടസ്സങ്ങളില്ലാതെ സമന്വയിപ്പിക്കുന്നു. സ്‌മാർട്ട് ഡ്രെയിനേജ് മാനേജ്‌മെൻ്റ് മുതൽ ഇൻ്റലിജൻ്റ് സെപ്‌റ്റിക് ടാങ്ക് സൊല്യൂഷനുകൾ വരെ, നിങ്ങളുടെ ഹോട്ടലിൻ്റെ ഇൻഫ്രാസ്ട്രക്ചർ മുഴുവൻ സമയവും സുരക്ഷിതമാണെന്ന് ഞങ്ങൾ ഉറപ്പാക്കുന്നു. അണ്ണാ ഡ്രെയിനേജ് സിസ്റ്റം സൊല്യൂഷനുകൾ ഉപയോഗിച്ച് ഒരു പുതിയ തലത്തിലുള്ള വിശ്വാസ്യതയും പാരിസ്ഥിതിക ഉത്തരവാദിത്തവും സ്വീകരിക്കുക - അവിടെ നൂതന സാങ്കേതികവിദ്യ നിങ്ങളുടെ ഹോട്ടലിൻ്റെ ഡ്രെയിനേജ്, എസ്ടിപി, മഴവെള്ള സംഭരണ ആവശ്യങ്ങൾ എന്നിവ നിറവേറ്റുന്നു.
  </p>
  <button className="bg-[#007A8C] text-white font-bold py-2 px-4 md:px-6 rounded-full text-sm md:text-base hover:bg-[#005F6E] transition-colors duration-300">
    കൂടുതൽ കണ്ടെത്തുക
  </button>
</div>
          </div>
        </div>
      </div>

      <div
        className="min-h-screen bg-cover bg-center bg-no-repeat relative flex items-center"
        style={{ backgroundImage: `url(${BgTex})` }}
      >
        <div className="container mx-auto px-4 relative z-10 flex justify-end">
          <div className="w-full md:w-1/2 bg-[#009DB8]/80 p-8 rounded-lg text-white">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
            നിങ്ങളുടെ വിരൽത്തുമ്പിൽ 
              <br />
              അനായാസമായ ഡ്രെയിനേജ് മാനേജ്മെൻ്റ്.

            </h2>
            <p className="font-poppins text-sm md:text-base   text-container  mb-6 max-w-2xl">
            സ്‌മാർട്ട് ഡ്രെയിനേജ് ടെക്‌നോളജി അനായാസമായ ജല മാനേജ്‌മെൻ്റ് കൊണ്ടുവരുന്നു, ഇത് ഡ്രെയിനേജ്, സെപ്‌റ്റിക് സിസ്റ്റങ്ങൾ, മഴവെള്ള സംഭരണം .
            </p>
            <button className="bg-[#00C2E0] text-white font-semibold py-2 px-6 rounded-full text-sm">
              Get Started
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white py-16">
  <div className="container mx-auto px-4 md:px-10">
    <h2 className="text-2xl font-bold mb-2 text-[#009DB8]">
      ഞങ്ങളുടെ സേവനങ്ങൾ
    </h2>
    <div className="md:gap-12 gap-6 mb-8 md:flex-row flex-col flex max-w-6xl">
      <div className="flex-1 md:max-w-[60%]">
        <h3 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 text-sm md:text-base text-container mb-6 max-w-2xl">
        "ANNA: STP, സെപ്റ്റിക് ടാങ്ക്, മഴവെള്ള സംഭരണ പരിഹാരങ്ങൾ വഴി സമ്പന്നമായ പരിഹാരങ്ങൾ"
        </h3>
      </div>
      <div className="flex-1 flex flex-col justify-between space-y-8 md:max-w-[40%]">
      <p className="font-poppins text-container text-gray-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 text-left leading-relaxed text-sm sm:text-base">
          ANNA ഡ്രെയിനേജ് സിസ്റ്റംസ് ദൈനംദിന ജീവിതം മെച്ചപ്പെടുത്തുന്നതിന് STP, സെപ്റ്റിക് ടാങ്ക്, മഴവെള്ള സംഭരണം എന്നിവയ്ക്കായുള്ള സുഗമമായ പരിഹാരങ്ങളിലൂടെ സാങ്കേതികവിദ്യ ഉപയോഗിക്കാൻ ലക്ഷ്യമിടുന്നു. കാര്യക്ഷമവും സുസ്ഥിരവുമായ പരിസ്ഥിതികൾ സൃഷ്ടിക്കാനും കണക്റ്റ് ചെയ്ത, പരിസ്ഥിതി സൗഹൃദ ലോകം പ്രോത്സാഹിപ്പിക്കാനും ഞങ്ങൾ ശ്രമിക്കുന്നു.
        </p>
        <button className="bg-[#009DB8] text-white font-bold py-2 px-4 md:px-6 rounded-full hover:bg-[#007A8C] transition-colors duration-300 text-sm md:text-base self-start">
          ഞങ്ങളെ ബന്ധപ്പെടുക
        </button>
      </div>
    </div>
  
          
          <div className="container mx-auto">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="flex-1 flex flex-col justify-between space-y-8 md:max-w-[40%]">
        <p className="font-poppins text-container text-gray-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 text-left leading-relaxed text-sm sm:text-base">
  ANNA സുഗമമായ STP, സെപ്റ്റിക് ടാങ്ക്, മഴവെള്ള സംഭരണ പരിഹാരങ്ങളിലൂടെ ദൈനംദിന ജീവിതം മെച്ചപ്പെടുത്താൻ സാങ്കേതികവിദ്യ ഉപയോഗിക്കാൻ ലക്ഷ്യമിടുന്നു. കാര്യക്ഷമവും സുസ്ഥിരവുമായ പരിസരങ്ങൾ സൃഷ്ടിക്കാനും, പരസ്പരം ബന്ധിപ്പിക്കപ്പെട്ടതും പരിസ്ഥിതി സൗഹൃദപരവുമായ ഒരു ലോകം പ്രോത്സാഹിപ്പിക്കാനും ഞങ്ങൾ പരിശ്രമിക്കുന്നു.
</p>

        </div>
      </div>

    
  

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto text-container  mb-6 max-w-2xl flex flex-col sm:flex-row items-center justify-between max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            {[
              {
                title: "മഴവെള്ള സംഭരണം",
                description:
                  "Anna ഡ്രെയിനേജ് സിസ്റ്റം സൊലൂഷനുകളുടെ മഴവെള്ള സംഭരണത്തിലൂടെ സുഖവും നവോത്ഥാനവും അനുഭവിക്കൂ.",
                bgColor: "bg-[#E6F7F9]",
                image: homeAutomationImage,
              },
              {
                title: "സെപ്റ്റിക് ടാങ്കുകൾ",
                description:
                  "Anna ഡ്രെയിനേജ് സിസ്റ്റങ്ങളിൽ നിന്ന് സെപ്റ്റിക് ടാങ്ക് പരിഹാരങ്ങൾക്ക് മത്സരാധിഷ്ഠിതമായ കൊട്ടുകൾ നേടുക, നിങ്ങളുടെ സ്വത്തുവിനുള്ള മികച്ച കാര്യക്ഷമതയും വിശ്വസനീയതയും ഉറപ്പാക്കുന്നു.",
                bgColor: "bg-white",
                image: homeTheatreImage,
              },
              {
                title: "മാലിന്യചാതന plantas",
                description:
                  "Anna ഡ്രെയിനേജ് സിസ്റ്റങ്ങളിൽ നിന്നുള്ള STP ഇൻസ്റ്റലേഷനുകൾക്കുള്ള മത്സരാധിഷ്ഠിതമായ കൊട്ടുകൾ നേടുക, നിങ്ങളുടെ ഹോട്ടലിന്റെ മികച്ച മാലിന്യസംവരണത്തിനായി.",
                bgColor: "bg-[#E6F7F9]",
                image: gateAutomationImage,
              },
            ].map((item, index) => (
              <div
                key={index}
                className={`${item.bgColor} shadow-md rounded-[25px] overflow-hidden flex flex-col`}
              >
                <div className="flex-grow flex items-center justify-center p-4">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-auto max-w-[256px] max-h-[256px] object-cover rounded-[25px]"
                  />
                </div>
                <div className="p-4 flex flex-col justify-between flex-grow">
                  <div>
                    <h2 className="text-xl font-bold mb-2">{item.title}</h2>
                    <p class="font-poppins text-container text-gray-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 leading-relaxed text-sm sm:text-base">{item.description}</p>
                  </div>
                  <button className="bg-yellow-400 text-black font-bold py-2 px-4 rounded-full self-start hover:bg-yellow-500 transition duration-300">
                  കൂടുതൽ പഠിക്കുക
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="text-left mt-8 ml-[9%]">
            <button className="border-2 border-[#009DB8] text-[#fff] font-bold py-2 px-4 md:px-6 rounded-full bg-[#009DB8] hover:text-white transition-colors duration-300 text-sm md:text-base">
            എല്ലാ സേവനങ്ങളും കാണുക
            </button>
          </div>
        </div>
      </div>
        {/* Stats Section */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-7 mb-12 mt-20 md:mt-44 mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="text-center p-4 border border-orange-500 rounded-[25px] w-full">
          <img src={Icon1} alt="Happy Customers" className="mx-auto mb-2 w-12 h-12 md:w-16 md:h-16" />
          <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-orange-500">
            <AnimatedNumberOnScroll target={350} />
          </h2>
          <p className="text-xs md:text-sm lg:text-base text-gray-600">സന്തോഷം അനുഭവിക്കുന്ന ഉപഭോക്താക്കൾ</p>
</div>
<div className="text-center p-4 border border-orange-500 rounded-[25px] w-full">
  <img src={Icon2} alt="5 Star Reviews" className="mx-auto mb-2 w-12 h-12 md:w-16 md:h-16" />
  <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-orange-500">
    <AnimatedNumberOnScroll target={300} />
  </h2>
  <p className="text-xs md:text-sm lg:text-base text-gray-600">5 Star റിവ്യൂസ്</p>
</div>
<div className="text-center p-4 border border-orange-500 rounded-[25px] w-full">
  <img src={Icon3} alt="Team Members" className="mx-auto mb-2 w-12 h-12 md:w-16 md:h-16" />
  <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-orange-500">
    <AnimatedNumberOnScroll target={20} />
  </h2>
  <p className="text-xs md:text-sm lg:text-base text-gray-600">ടീം അംഗങ്ങൾ</p>
</div>
<div className="text-center p-4 border border-orange-500 rounded-[25px] w-full">
  <img src={Icon4} alt="Installations" className="mx-auto mb-2 w-12 h-12 md:w-16 md:h-16" />
  <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-orange-500">
    <AnimatedNumberOnScroll target={500} />
  </h2>
  <p className="text-xs md:text-sm lg:text-base text-gray-600">സ്ഥാപനങ്ങൾ</p>
</div>

      </div>
    </div>

      <div className="bg-[#cccccc] py-16">
        <div className="container mx-auto px-4 md:px-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white rounded-[25px] p-6 shadow-md text-center">
              <div className="bg-[#009DB8] w-12 h-12 rounded-full flex items-center justify-center mb-4 mx-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-white"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.87-3.13-7-7-7zm-1 14h2v1h-2v-1zm3-2H10v-1h4v1zm2.85-5.27l-.85.6V11h-2v-1.93l-2.28 1.61-2.29-1.61V11H7V9.33l-.85-.6C5.43 8.17 5 7.15 5 6h2c0 1.66 1.34 3 3 3s3-1.34 3-3h2c0 1.15-.43 2.17-1.15 2.73z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">ഞങ്ങളുടെ വീക്ഷണം</h3>
              <p class="font-poppins text-container text-gray-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 leading-relaxed text-sm sm:text-base">
  അന്ന സുഗമമായ എസ്‌ടിപി, സെപ്റ്റിക് ടാങ്ക്, മഴവെള്ള സംഭരണ പരിഹാരങ്ങളിലൂടെ ദൈനംദിന ജീവിതം മെച്ചപ്പെടുത്താൻ സാങ്കേതികവിദ്യ ഉപയോഗിക്കാൻ ലക്ഷ്യമിടുന്നു. കാര്യക്ഷമവും സുസ്ഥിരവുമായ പരിസരങ്ങൾ സൃഷ്ടിക്കാനും, പരസ്പരം ബന്ധിപ്പിക്കപ്പെട്ടതും പരിസ്ഥിതി സൗഹൃദപരവുമായ ഒരു ലോകം പ്രോത്സാഹിപ്പിക്കാനും ഞങ്ങൾ പരിശ്രമിക്കുന്നു.
</p>

            </div>

            <div className="bg-white rounded-[25px] p-6 shadow-md text-center">
              <div className="bg-[#009DB8] w-12 h-12 rounded-full flex items-center justify-center mb-4 mx-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-8 text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="22" y1="12" x2="18" y2="12"></line>
                  <line x1="6" y1="12" x2="2" y2="12"></line>
                  <line x1="12" y1="6" x2="12" y2="2"></line>
                  <line x1="12" y1="22" x2="12" y2="18"></line>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">ഞങ്ങളുടെ ദൗത്യം</h3>
              <p class="font-poppins text-container text-gray-600 mb-4 sm:mb-6 max-w-2xl mx-auto px-2 sm:px-4 leading-relaxed text-sm sm:text-base">
  STP സിസ്റ്റങ്ങൾ, സെപ്റ്റിക് ടാങ്ക് പരിഹാരങ്ങൾ, മഴവെള്ള സംഭരണം എന്നിവ ഉൾപ്പെടുന്ന ഞങ്ങളുടെ സമഗ്ര സേവനങ്ങൾ,  സുസ്ഥിരത, വിശ്വസനീയത എന്നിവ മെച്ചപ്പെടുത്താൻ രൂപകൽപന ചെയ്യപ്പെട്ടതാണ്.
</p>

            </div>

            <div className="space-y-4">
              <h3 className="text-xl text-[#009DB8] font-bold mb-2">
                Our Value
              </h3>
              <h4 className="text-2xl font-bold mb-4">
   ആധുനിക STP, സെപ്റ്റിക് ടാങ്ക്, മഴവെള്ള സംഭരണ പരിഹാരങ്ങളിലൂടെ ആനുകൂല്യമുള്ള മാറ്റം വരുത്താം.
</h4>

              <img
                src={BbImg}
                alt=""
                className="w-full h-48 object-cover rounded-[25px] mb-4"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
